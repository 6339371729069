import { defineEntityGetAllProcedure, defineEntityGetByIdProcedure, defineProcedure, defineRouter } from '@nest/common-rpc-shared';
import { entities } from '@nest/domain-entities-declarations';
import { PartyDto } from '@nest/domain-entities-metadata-dtos';
import { type } from '@nest/utils-shared';
import { CreatePartyDtoSchema, UpdatePartyDtoSchema } from './zod';

export const partiesRouter = defineRouter({
	parties: {
		addOrUpdateGpsToParty: defineProcedure({
			output: type<void>()
		}),
		create: defineProcedure({
			input: CreatePartyDtoSchema,
			output: type<PartyDto>()
		}),
		update: defineProcedure({
			input: UpdatePartyDtoSchema,
			output: type<PartyDto>()
		}),
		getById: defineEntityGetByIdProcedure(entities.parties),
		getAll: defineEntityGetAllProcedure(entities.parties)
	}
});
