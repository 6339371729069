export enum PartyLawType {
	Company = 'Company', // Pravnicka
	Individual = 'Individual', // Fyzicka
	Private = 'Private' // Osobni
}

export enum PartyApprovedState {
	Pending = 'Pending',
	Approved = 'Approved',
	Rejected = 'Rejected',
	Blocked = 'Blocked'
}

export enum InvoiceDeliveryMethodType {
	None = 'None',
	Print = 'Print',
	Email = 'Email'
}

export enum SepaPaymentType {
	CORE = 'CORE',
	B2B = 'B2B'
}

export enum SepaSequenceType {
	FRST = 'FRST',
	RCUR = 'RCUR'
}

export enum BatchInvoicingType {
	Print = 'Print',
	Email = 'Email',
	PrintAndEmail = 'PrintAndEmail'
}
