import { Directive, inject, input } from '@angular/core';
import { injectRpcClient } from '@nest/common-core-frontend';
import { partiesRouter } from '@nest/domain-parties-shared';
import { SelectComponent, SelectStore } from '@nest/uikit-select-ui';
import { apiQueryContains } from '@nest/utils-shared';
import { PartyAutocompleteOptionComponent } from './party-autocomplete-option.component';

@Directive({
	selector: '[appPartiesAutocomplete]',
	standalone: true
})
export class PartyAutocompleteDirective {
	store = inject(SelectStore);
	partiesClient = injectRpcClient(partiesRouter);
	select = inject(SelectComponent);

	_typeCheck = input<void>(null as unknown as void, { alias: 'appPartiesAutocomplete' });
	filter = input<(typeof this.partiesClient.parties.getAll.$input)['filter']>(undefined);

	constructor() {
		this.select.optionComponentOutlet.set(PartyAutocompleteOptionComponent);
		this.select.selectedOptionComponentOutlet.set(PartyAutocompleteOptionComponent);

		this.store.patchConfig({
			debounceTime: 300,
			dataSourceFn: async (term: string, offset: number) =>
				this.partiesClient.parties.getAll({
					filter: {
						name: apiQueryContains(term),
						...(this.filter() ?? {})
					},
					limit: 20,
					offset
				})
		});
	}
}
