import { PartySchema } from '@nest/domain-entities-metadata-shared-zod';
import { z } from 'zod';

export const CreatePartyDtoSchema = PartySchema.pick({
	name: true,
	isBillingContact: true,
	isOrganization: true,
	isSupplier: true,
	idNumber: true,
	vatNumber: true,
	code: true,
	lawType: true,
	taxMode: true,
	language: true,
	taxRegion: true,
	defaultPaymentType: true,
	invoiceDeliveryMethodType: true,
	invoiceDueDays: true,
	street: true,
	city: true,
	zipCode: true,
	country: true,
	gps: true,
	shipmentLineCode: true,
	textMark: true,
	approvedState: true,
	currency: true,
	contactPersonName: true,
	phone: true,
	email: true,
	notificationEmail: true,
	creditLimit: true,
	deliverySenderInfo: true,
	sepaMandateReference: true,
	sepaMandateDateOfSignature: true,
	batchInvoiceRule: true,
	salesman: true,
	note: true,
	noteForDriver: true,
	pricelists: true,
	parent: true,
	bankAccounts: true,
	tags: true,
	timeWindows: true
});

export const UpdatePartyDtoSchema = CreatePartyDtoSchema.extend({
	id: z.number()
});

export type CreatePartyDto = z.infer<typeof CreatePartyDtoSchema>;
export type UpdatePartyDto = z.infer<typeof UpdatePartyDtoSchema>;
